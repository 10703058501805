.edit-card {


    .edit-form {
        display: flex;
        flex-direction: column;

        .edit-form-content {
            flex: 1 0 auto;
        }

        .edit-form-footer {
            flex-shrink: 0;

            .edit-form-button {
                margin-right: 16px;
                margin-top: 16px;

                .button-indicator {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-left: -15px;
                    margin-top: -12px;
                }
            }
        }
    }
}

textarea.dx-texteditor-input.dx-texteditor-input-auto-resize {
    padding-top: 7px !important;
    padding-bottom: 4px !important;
}