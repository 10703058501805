.mdi-icon-button {
    .dx-svg-icon {
        width: auto !important;
        height: auto !important;
    }
}

#eye-wrapper {
    font-size: 50px;
    text-align: center;
    padding-bottom: 50px;
}

.visus-wrapper {
    background-color: white;

    .no-padding .dx-button-content {
        padding: 0 !important;
    }
}

.dx-overlay-wrapper.dx-popup-wrapper.dx-overlay-modal.dx-overlay-shader {
    z-index: 9999 !important;
}

.last {
    margin-bottom: 16px;
}

.extended {
    margin-bottom: 16px;
}

.binocular-red-values {
    color: rgb(244, 67, 54);
}

.cell-formatted {
    text-align: left !important;
}

.binocular-pd {
    margin-top: 32px;
}