@import "./themes/generated/variables.vizweb.scss";
@import "./styles.variables.scss";

.app {
    background-color: darken($base-bg, 5.00);
    display: flex;
    height: 100%;
    width: 100%;
}

.app .dx-card.content {
    height: 100% !important;
}

.app .content {
    line-height: 1.5;

    h2 {
        font-size: 30px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.app .content-block {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 20px;
}

.screen-x-small .content-block {
    margin-left: 20px;
    margin-right: 20px;
}

.responsive-paddings {
    padding: 20px;

    .screen-large & {
        padding: 40px;
    }
}

.dx-card.wide-card {
    border-radius: 0;
    margin: 0;
    border-right: 0;
    border-left: 0;
}

.with-footer > .dx-scrollable-wrapper >
.dx-scrollable-container > .dx-scrollable-content {
    height: 100%;

    & > .dx-scrollview-content {
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }
}

html, body {
    margin: 0px;
    min-height: 100%;
    height: 100%;
}

#root {
    height: 100%;
}

* {
    box-sizing: border-box;
}

.dx-overlay-wrapper.dx-popup-wrapper.dx-popover-wrapper.dx-dropdownmenu-popup-wrapper {
    z-index: 3000 !important;
    /*overflow: hidden;*/
}

//.dx-dropdowneditor-overlay.dx-popup-wrapper .dx-overlay-content
.dx-loadpanel-content {
    box-shadow: none !important;
    border: none;
    background: 0 0;
}


.dx-toast-wrapper {
    z-index: 9999 !important;
}


html.dxSafari {
    .dx-drawer-wrapper {
        height: initial;

        .dx-drawer-content {
            height: initial;
        }
    }
}

.test-description {
    padding-bottom: 20px;
}