@import "../../themes/generated/variables.vizweb.scss";
@import "../../styles.variables.scss";

.header-component {
    flex: 0 0 auto;
    z-index: 1; //1503;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    /*position: absolute;
  top: 0;
  left: 0;
  right: 0;*/
    .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
        color: $base-accent;
    }

    .header-toolbar {
        overflow: hidden;
    }
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 40px;

  .screen-x-small & {
    padding: 0 20px;
  }
}

.dx-toolbar-menu-section .dx-toolbar-hidden-button .dx-button.dx-button-default {
    background-color: $button-default-bg !important;
    border-radius: 0;

    &:hover {
        background-color: $button-default-hover-bg !important;
    }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
    width: $side-panel-min-width;
    text-align: center;
    padding: 0;
}

.header-title {
    min-width: 190px;

    .dx-item-content {
        padding: 0;
        margin: 0;
    }
}

.user-button > .dx-button-content {
    padding: 0 6px !important;
}

.dx-theme-generic {
  .dx-toolbar {
    padding: 10px 0;
  }

  .user-button>.dx-button-content {
    padding: 3px;
  }
}

.toolbar-main-menu-button {
    
}

.dx-toolbar-item .dx-button.dx-button-has-text:not(.dx-button-has-icon):not(.dx-shape-standard),
.dx-toolbar-item .dx-dropdownbutton .dx-buttongroup-item {
    box-shadow: none;
}