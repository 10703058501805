@import "../../themes/generated/variables.vizweb.scss";

.error-card {
    margin: auto auto;
    padding: 20px;
    flex-grow: 0;

    h1 {
        margin: 0;
        font-size: 200px !important;
        color: $button-default-bg;
    }

    h4 {
        margin: 0;
        font-weight: lighter !important;
        margin-bottom: 30px;
        text-transform: uppercase;
    }

    .dx-button {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.error-card-scroll-view > .dx-scrollable-wrapper >
.dx-scrollable-container > .dx-scrollable-content {
    height: 100%;

    & > .dx-scrollview-content {
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }
}