$base-font-family: "Roboto", "RobotoFallback", "Noto Kufi Arabic", "Helvetica", "Arial", sans-serif;
$base-text-color: #fff;
$base-label-color: #fff;
$base-link-color: #009688;
$base-icon-color: rgba(255, 255, 255, 0.54);
$base-spin-icon-color: rgba(255, 255, 255, 0.54);
$base-bg: #363640;
$base-border-color: #515159;
$base-success: #8BC34A;
$base-warning: #FFC107;
$base-danger: #F44336;
$base-hover-color: #fff;
$base-hover-bg: rgba(255, 255, 255, 0.05);
$base-focus-color: rgba(0, 0, 0, 0.87);
$base-focus-bg: rgba(255, 255, 255, 0.05);
$badge-color: rgba(0, 0, 0, 0.87);
$badge-bg: #009688;
$texteditor-color: #fff;
$texteditor-placeholder-color: #808080;
$texteditor-bg: rgba(255, 255, 255, 0.04);
$texteditor-focused-bg: rgba(255, 255, 255, 0.04);
$texteditor-border-color: rgba(255, 255, 255, 0.42);
$texteditor-hover-border-color: #fff;
$texteditor-focused-border-color: #009688;
$texteditor-hover-bg: rgba(255, 255, 255, 0.07);
$textbox-search-icon-color: rgba(255, 255, 255, 0.54);
$accordion-title-color: #fff;
$accordion-item-border-color: #515159;
$accordion-icon-active-color: #fff;
$button-normal-color: #fff;
$button-normal-bg: #363640;
$button-normal-hover-bg: #494956;
$button-normal-focused-bg: #494956;
$button-normal-active-bg: #7d7d92;
$button-default-color: rgba(0, 0, 0, 0.87);
$button-default-bg: #009688;
$button-default-hover-bg: #00c1af;
$button-default-focused-bg: #00c1af;
$button-default-active-bg: #26ffeb;
$button-danger-color: rgba(0, 0, 0, 0.87);
$button-danger-bg: #F44336;
$button-danger-hover-bg: #f6695f;
$button-danger-focused-bg: #f6695f;
$button-danger-active-bg: #f99b94;
$button-success-color: rgba(0, 0, 0, 0.87);
$button-success-bg: #8BC34A;
$button-success-hover-bg: #a0ce6b;
$button-success-focused-bg: #a0ce6b;
$button-success-active-bg: #bbdc95;
$button-group-normal-selected-color: #fff;
$button-group-normal-selected-bg: rgba(255, 255, 255, 0.18);
$button-group-default-selected-color: #009688;
$button-group-default-selected-bg: rgba(0, 150, 136, 0.22);
$button-group-danger-selected-color: #F44336;
$button-group-danger-selected-bg: rgba(244, 67, 54, 0.22);
$button-group-success-selected-color: #8BC34A;
$button-group-success-selected-bg: rgba(139, 195, 74, 0.22);
$drawer-shader-background-color: rgba(0, 0, 0, 0.5);
$fileuploader-filename-color: #fff;
$fileuploader-falename-status-color: #ffffff;
$fileuploader-border-color: #515159;
$gallery-indicator-bg: #363640;
$gallery-indicator-item-selected-bg: #009688;
$gallery-indicator-focused-bg: #009688;
$gallery-navbutton-hover-color: #009688;
$gallery-navbutton-active-color: #009688;
$list-border-color: #515159;
$list-group-header-bg: rgba(54, 54, 64, 0);
$list-normal-color: #fff;
$list-group-color: rgba(255, 255, 255, 0.6);
$list-expanded-group-color: #009688;
$list-item-hover-bg: rgba(255, 255, 255, 0.05);
$list-item-selected-bg: rgba(110, 110, 128, 0.5);
$list-item-active-bg: #656577;
$overlay-content-bg: #363640;
$overlay-shader-bg: rgba(0, 0, 0, 0.61);
$popup-title-bg: transparent;
$dropdowneditor-icon-active-color: rgba(255, 255, 255, 0.54);
$selectbox-list-bg: #363640;
$tabs-tab-color: rgba(255, 255, 255, 0.54);
$tabs-tab-selected-color: #009688;
$tabs-selected-tab-bottom-border-color: #009688;
$tabs-tab-bg: #2f2f38;
$tabs-hovered-tab-bg-color: #464653;
$tagbox-tag-color: rgba(255, 255, 255, 0.6);
$tagbox-tag-bg: #525261;
$tagbox-tag-active-color: #fff;
$tagbox-tag-button-remove-bg: rgba(0, 0, 0, 0.36);
$navbar-tab-color: #fff;
$navbar-tab-selected-color: #fff;
$navbar-tab-bg: #2f2f38;
$navbar-tab-selected-bg: #363640;
$scrollable-scroll-bg: rgba(112, 112, 133, 0.7);
$toolbar-bg: #363640;
$toast-color: rgba(255, 255, 255, 0.87);
$toast-info-bg: rgba(0, 0, 0, 0.87);
$toast-warning-bg: #FFC107;
$toast-error-bg: #F44336;
$toast-success-bg: #8BC34A;
$load-indicator-segment-bg-color: #009688;
$loadpanel-content-bg: #363640;
$material-slider-bar-bg: rgba(0, 150, 136, 0.24);
$material-slider-bg: #009688;
$material-slider-tooltip-color: rgba(0, 0, 0, 0.87);
$progressbar-bg: rgba(0, 150, 136, 0.3);
$progressbar-range-bg: #009688;
$progressbar-label-color: #009688;
$switch-bg: #9494a4;
$switch-handle-off-bg: #3b3b46;
$switch-on-bg: rgba(0, 150, 136, 0.5);
$switch-handle-on-bg: #009688;
$pager-page-selected-color: rgba(0, 0, 0, 0.87);
$pager-page-selected-bg: #009688;
$colorbox-overlay-bg: #363640;
$datagrid-columnchooser-item-color: rgba(255, 255, 255, 0.6);
$datagrid-columnchooser-font-weight: 500;
$datagrid-drag-header-border-color: rgba(0, 150, 136, 0.5);
$datagrid-selection-bg: rgba(0, 150, 136, 0.07);
$datagrid-row-selected-border-color: #515159;
$datagrid-row-selected-color: #fff;
$datagrid-row-focused-color: rgba(0, 0, 0, 0.87);
$datagrid-row-focused-bg: #0b837a;
$datagrid-menu-icon-color: rgba(255, 255, 255, 0.54);
$datagrid-cell-modified-border-color: rgba(139, 195, 74, 0.32);
$datagrid-row-invalid-faded-border-color: rgba(244, 67, 54, 0.32);
$datagrid-group-row-color: rgba(255, 255, 255, 0.54);
$datagrid-group-row-bg: #363640;
$datagrid-search-color: rgba(0, 0, 0, 0.87);
$datagrid-search-bg: #009688;
$datagrid-row-error-color: #ffffff;
$datagrid-row-error-bg: #a83e3a;
$datagrid-focused-border-color: #0b837a;
$datagrid-editor-bg: rgba(54, 54, 64, 0);
$datagrid-row-alternation-bg: #3f3f4b;
$datagrid-summary-color: rgba(255, 255, 255, 0.7);
$PIVOTGRID_AREA_COLOR: rgba(255, 255, 255, 0.54);
$PIVOTGRID_TOTALCOLOR: rgba(23, 23, 27, 0.2);
$PIVOTGRID_GRANDTOTALCOLOR: rgba(255, 255, 255, 0.05);
$PIVOTGRID_FIELD_AREA_TEXT_COLOR: rgba(188, 188, 188, 0.3);
$SCHEDULER_BASE_BORDER_COLOR: #515159;
$SCHEDULER_ACCENT_BORDER_COLOR: #515159;
$SCHEDULER_APPOINTMENT_TEXT_COLOR: rgba(0, 0, 0, 0.87);
$SCHEDULER_APPOINTMENT_BASE_COLOR: #009688;
$SCHEDULER_APPOINTMENT_START_COLOR: rgba(0, 0, 0, 0.3);
$SCHEDULER_WORKSPACE_FOCUSED_CELL_COLOR: rgba(0, 150, 136, 0.12);
$SCHEDULER_FIRST_MONTH_BORDER_COLOR: #515159;
$SCHEDULER_CURRENT_TIME_CELL_COLOR: #009688;
$menu-color: #ffffff;
$menu-item-selected-bg: #494956;
$calendar-header-color: #ffffff;
$calendar-cell-other-color: #ffffff;
$calendar-cell-selected-bg: #009688;
$calendar-shevron-icon-color: #009688;
$tooltip-color: #fff;
$tooltip-bg: #000000;
$tileview-color: #fff;
$treeview-focused-bg: rgba(255, 255, 255, 0.05);
$form-group-border-color: #515159;
$filterbuilder-text-color: #fff;
$filterbuilder-text-focus-color: #FFF;
$filterbuilder-plus-icon-color: rgba(139, 195, 74, 0.7);
$filterbuilder-remove-icon-color: rgba(244, 67, 54, 0.7);
$filterbuilder-group-operation-color: rgba(244, 67, 54, 0.3);
$filterbuilder-item-field-color: rgba(0, 150, 136, 0.3);
$filterbuilder-item-operator-color: rgba(139, 195, 74, 0.3);
$filterbuilder-item-value-color: rgba(81, 81, 89, 0.5);
$base-accent: #009688;
$base-border-radius: 2px;
$radiogroup-checked-bg: #009688;
$radiogroup-border-color: rgba(255, 255, 255, 0.54);
$radiogroup-bg: #363640;
$accordion-color: #fff;
$accordion-background-color: #363640;
$accordion-title-active-color: #fff;
$accordion-item-hover-bg: rgba(255, 255, 255, 0.05);
$accordion-title-active-bg: rgba(255, 255, 255, 0.05);
$checkbox-border-color: rgba(255, 255, 255, 0.54);
$checkbox-bg: #009688;
$checkbox-hover-border-color: #005951;
$base-invalid-color: #F44336;
$base-invalid-faded-border-color: rgba(244, 67, 54, 0.4);
$list-item-focused-selected-bg: rgba(110, 110, 128, 0.4);
$navbar-tab-focused-border-color: rgba(255, 255, 255, 0.05);
$datagrid-base-color: #fff;
$datagrid-base-background-color: #363640;
$datagrid-border-color: #515159;
$datagrid-hover-bg: rgba(255, 255, 255, 0.05);
$datagrid-row-hovered-color: #fff;
$datagrid-nodata-color: #ffffff;
$datagrid-link-color: #009688;
$datagrid-columnchooser-bg: #363640;
$PIVOTGRID_BORDER_COLOR: #515159;
$PIVOTGRID_DATA_AREA_COLOR: #fff;
$scheduler-cell-hover-bg: rgba(255, 255, 255, 0.05);
$SCHEDULER_PANEL_TEXT_COLOR: rgba(255, 255, 255, 0.54);
$SCHEDULER_WORKSPACE_BACKGROUND_COLOR: #363640;
$SCHEDULER_WORKSPACE_ACCENT_COLOR: #009688;
$menu-popup-bg: #363640;
$menu-item-hover-bg: rgba(255, 255, 255, 0.05);
$menu-popup-border-color: #515159;
$calendar-color: #fff;
$calendar-hover-bg: rgba(255, 255, 255, 0.05);
$calendar-cell-active-bg: rgba(0, 0, 0, 0.2);
$treeview-hover-bg: rgba(255, 255, 255, 0.05);
$treeview-border-color: #515159;
$form-field-item-color: #ffffff;
$filterbuilder-bg: #363640;
$datagrid-chevron-icon-color: rgba(255, 255, 255, 0.54);
$datagrid-filter-row-background-color: #31313a;
$datagrid-active-header-filter-icon-color: #009688;
$datagrid-filter-panel-color: #009688;
$pivotgrid-chevron-icon-color: rgba(255, 255, 255, 0.54);
$menu-item-expanded-color: #ffffff;
$numberbox-spin-icon-color: rgba(255, 255, 255, 0.54);
$treeview-spin-icon-color: rgba(255, 255, 255, 0.54);
