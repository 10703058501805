$tab-background: #fff;

#optotype-wrapper {
    padding-top: 56px;

    & > .dx-tabs {
        margin-bottom: 20px;
        background: $tab-background;
        border-bottom: 1px solid #ccc;

        .dx-item.dx-tab {
            background: $tab-background;
        }
    }

    .dx-tile {
        border: none;

        .dx-tile-image {
            border: 2px solid rgb(242, 242, 242);
            width: 100%;
            height: 100%;
            background-color: #F2F2F2;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            display: block;
        }

        .selected {
            border-color: rgb(0, 150, 136);
        }
    }

    .dx-tabs-nav-button {
        margin-top: 7px;
    }

    .tabImage {
        width: 49px;
        height: 37px;
        background-repeat: no-repeat;
        background-size: 49px 37px;
    }
}
