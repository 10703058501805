#cylinder-wrapper {
    display: grid;

    #eye-wrapper {
        font-size: 50px;
        text-align: center;
        padding-bottom: 50px;
    }

    #axis-wrapper {
        display: flex;
        padding-bottom: 50px;

        #axis-value {
            font-size: 50px;
            text-align: center;
            margin: auto;
        }

        .axis-buttons {
            display: flex;
            flex-direction: column;
        }

        .dx-button {
            margin-bottom: 5px;
            margin-top: 5px;
            width: 50px;
            height: 50px;
            border-radius: 50%;

            .dx-button-content {
                padding: 0px;
            }
        }
    }

    #visus-wrapper {
        display: flex;
        padding-bottom: 50px;

        #visus-value {
            font-size: 50px;
            text-align: center;
        }

        .dx-button {
            width: 50px;
            height: 50px;
            margin: auto;
        }
    }
}
