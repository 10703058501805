#duochromatic-wrapper {
    display: grid;

    #eye-wrapper {
        font-size: 50px;
        text-align: center;
        padding-bottom: 50px;
    }

    .dx-button {
        margin-bottom: 40px;
        margin-top: 10px;
    }

    #axis-wrapper {
        display: flex;
        padding-bottom: 50px;

        #axis-value {
            font-size: 50px;
            text-align: center;
        }

        .dx-button {
            width: 50px;
            height: 50px;
            margin: auto;
        }
    }
}
