.button-loading {
    margin-right: 16px;
    margin-top: 16px;

    .button-indicator {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -15px;
        margin-top: -12px;
    }
}