.side-nav-outer-toolbar {
    flex-direction: column;
    display: flex;
    height: 100%;
    width: 100%;
}

.layout-header {
    z-index: 1501;
}

.layout-body {
    flex: 1;
    min-height: 0;
}

.content {
    flex-grow: 1;
    //padding: 0 40px 40px;
    padding: 0;
    margin-top: 0px;
    //height: calc(100vh - 76px) !important;
}

.dx-drawer-shader {
    left: 0;
    z-index: 200 !important;
}

.dx-drawer-panel-content {
    z-index: 9999;
}
.header-toolbar-no-menu {
    padding-left: 40px !important;
}
