@import "../../themes/generated/variables.vizweb.scss";


.user-menu-list {
    .dx-list-group-header {
        height: 36px;
        text-transform: uppercase;
        font-weight: 500;
        color: $base-text-color;

        div:nth-child(2) {
            padding-right: 18px;
        }
    }

    .dx-list-item-content {
        padding: 0;

        .dx-button {

            .dx-button-content {
                padding: 0 8px;

                .dx-button-text {
                    font-weight: normal;
                    text-transform: none;
                }
            }
        }
    }
}

.dx-dropdownbutton-popup-wrapper {

    .dx-item-content.dx-list-item-content {
        padding: 0 !important;

        & > div {
            padding: 10px 7px;

            &.active {
                background-color: $button-default-bg;
                color: $button-default-color;
            }
        }
    }

    .dx-item.dx-list-item.dx-state-hover {
        div.active {
            background-color: $button-default-hover-bg;
        }
    }
}