#remote-wrapper {

    &.not-optotype {
        margin: auto;
        padding: 56px; // toolbar height
    }

    .dx-toolbar {
        position: absolute;
        top: 0;
        left: 0;
        padding-right: 20px;
        background-color: transparent !important;

        .dx-dropdownmenu.dx-button {
            background-color: transparent !important;
        }
    }
}